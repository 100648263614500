@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');

@keyframes spin{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fade{
  0% {
    opacity: .1;
  }
  50% {
    opacity: 0.4;
  }
  100%{
    opacity: .1;
  }
}

strong{
  color: rgb(251, 146, 60);
}


body {
  background-color: rgb(229, 229, 229);
  margin: 0;
  font-family: 'Roboto Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.bg-wrapper{
  background-color: #e4e4e4;
  background-image: url(./ttten.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.text-shadow{
  text-shadow: 2px 2px 2px rgb(163, 163, 163);
}
.text-shadow-black{
  text-shadow: 2px 2px 5px rgb(26, 26, 26);
}
.custom-shape-divider-top-1685171502 {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
.custom-shape-divider-top-1685171502 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 93px;
}

.custom-shape-divider-top-1685171502 .shape-fill {
    fill: #404040;
}
.custom-shape-divider-top-1685171502 .shape-fill2 {
    fill: #e4e4e4;
}
.vortex-bg{
  background-image: url(./vvvortex.svg);
  width: 55rem;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.2;
  scale: 2.3;
  height: 55rem;
  margin: 0;
  padding: 0;
  animation: spin 90s linear infinite;
}
.scribble{
  background-image: url(./ssscribble.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .project-bg{ */
/*   background-image: url(./stacked-waves-haikei.svg); */
/*   background-size: cover; */
/*   background-repeat: no-repeat; */
/* } */
::-webkit-scrollbar{
  width: 10px;
}

::-webkit-scrollbar-track{
  background-color: #ffffff00;
}


::-webkit-scrollbar-thumb{
  background-color: #ff9249;
}
.title-transition{
  transition: all 0.3s ease-out;
}
.title-transition:hover{
  scale: 1.1;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #333;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}



@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
